.App {
    text-align: center;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .info-item {
    width: 50vw;
    height: 3vh;
    padding: 0em;
    border: 0px solid rgb(159, 12, 22);
    align-self: center;
    background-color: rgb(230, 230, 230);
    border-radius: 0em;
  }